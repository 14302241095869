/*******************************************************************************
Small-Width Styles
*******************************************************************************/

/* Max-Width: 992px (Low-Resolution Displays) */
@media screen and (max-width: 992px) {
  img {
    max-width: 100%;
  }

  body {
    padding: 14px;
  }
}

/* Low Res but above tablet */
@media screen and (max-width: 550px) {
  body {
    padding: 14px;
  }

  #logo {
    font-size: 8px;
  }
}


/* Max-Width: 480px (Tablet) */
@media screen and (max-width: 480px) {
  body {
    font-size: 14px;
    padding: 14px;
  }

  .inner {
    min-width: 320px;
    max-width: 480px;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 21px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 14px;
  }

  code,
  pre {
    font-size: 11px;
  }

  #logo {
    font-size: 7px;
  }

  .copyright {
    font-size: 12px;
  }
}

/* Small Tablet */
@media screen and (max-width: 420px) {
  body {
    padding: 14px;
  }

  #logo {
    font-size: 7px;
  }

  #options-container {
    padding-top: 20px;
    padding-bottom: 20px;
    display: block;
  }

  #filtering-container {
      float: initial;
      text-align: left;
      display: grid;
  }
}

@media screen and (max-width: 380px) {
  body {
    padding: 14px;
  }

  #logo {
    font-size: 6.5px;
  }
}

@media screen and (max-width: 350px) {
  body {
    padding: 14px;
  }

  #logo {
    font-size: 6px;
  }
}

/* Max-Width: 320px (Mobile) */
@media screen and (max-width: 320px) {
  body {
    font-size: 14px;
  }

  .inner {
    min-width: 240px;
    max-width: 320px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 21px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 15px;
  }

  h6 {
    font-size: 14px;
  }

  code,
  pre {
    min-width: 240px;
    max-width: 320px;
    font-size: 11px;
  }

  #logo {
    font-size: 5.5px;
  }
}
