@import "res/thirdparty/gridism"; // Import gridism.css

#table-of-contents {
    font-size: 12px;
    padding-left: 0px;
    list-style: none;
    text-align: right;
}

#toc {
    font-size: 12px;
    padding-left: 0px;
    list-style: none;
    text-align: right;
}

#toc ul {
    list-style: upper-roman;
    text-align: right;
    direction: rtl;
}

#toc ul, ol, dl {
    margin-bottom: 0;
}

.unit.four-fifths {
    padding-top: 0px;
}

.unit.one-fifth {
    padding-left: 10px;
}

.grid .unit:first-child {
    padding-left: 0px;
}

#main_content .unit.one-fifth {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
}