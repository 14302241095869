/* Footer Styling */
footer p {
  color: #373737;
  text-align: center;
}

footer a {
  color: #000000;
  text-decoration: underline;
}

/* Required for Sticky Footer */
html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

#main_content_wrap {
  flex: 1 0 auto;
}

#footer_wrap {
  flex-shrink: 0;
  visibility: visible;
  border: 0px;
  border-top: 1px;
  border-style: dashed;
  border-color: lightgray;
}

.copyright {
  margin: 0;
}

footer a {
  text-decoration: none;
}
