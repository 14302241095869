.skill-tag {
    color: #39739d;
    background-color: #e1ecf4;
    border-color: #00000000;
    font-size: 12px;
    display: inline-block;
    padding: 0.4em 0.5em;
    margin: 2px 2px 2px 0;
    line-height: 1;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    cursor: pointer;
}
