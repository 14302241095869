/* FontAwesome */
#social-links .fab {
    padding: 5px 5px 5px 5px;
    font-size: 24px;
    width: 30px;
    text-align: center;
    text-decoration: none;
}
  
#social-links .fas {
    padding: 5px 5px 5px 5px;
    font-size: 24px;
    width: 30px;
    text-align: center;
    text-decoration: none;
}

.fab:hover {
    opacity: 0.5;
}
  
.fab:hover, .fab:active, .fab:visited, .fab:focus {
    text-decoration:none;
}

.fas:hover {
    opacity: 0.5;
}
  
.fas:hover, .fas:active, .fas:visited, .fas:focus {
    text-decoration:none;
}

.fa-at {
    color: #24292e;
}

.fa-linkedin {
    color: #00a6ff;
}
  
.fa-twitter {
    color: #1DA1F2;
}

.fa-youtube {
    color: #FF0000;
}

.fa-github {
    color: #24292e;
}

#social-links {
   text-align: center;
}