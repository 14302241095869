/* Input Styling */
input {
    width: 100%;
    border: 0;
    font-family: auto;
}

#results-container li {
    font-weight: bold;
    list-style: square;
    letter-spacing: -1px;
}