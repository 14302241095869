/*******************************************************************************
Main Theme
*******************************************************************************/

/* Variables */
$dev-serif-fonts: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono,
    Courier New, Terminal, monospace, serif;
$dev-editor-fonts: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono,
    Courier New, Terminal, monospace, serif;

/* Imports */
@import "res/reset"; // Reset CSS Layout
@import "res/base-theme"; // Import Base Theme
@import "res/navigation"; // Navigation Bar Styling
@import "res/full-width-styles"; // Import Full-Width Styles
@import "res/small-width-styles"; // Import Small-Width Styles (Mobile)
@import "res/table-of-contents"; // Table of Contents (Nav) Styling
@import "res/font-awesome"; // Font-Awesome Styling
@import "res/input"; // Input Styling
@import "res/skills"; // Skills Styling
@import "res/sticky-footer"; // Flexbox for sticky footer
@import "res/code-theme-dark"; // Code Element Style