/*******************************************************************************
Full-Width Styles
*******************************************************************************/

.outer {
    width: 100%;
}

.inner {
    position: relative;
    max-width: 870px; /* 670px */
    padding: 0px 0px;
    margin: 0 auto;
}
