/*******************************************************************************
Base Theme
*******************************************************************************/

#global::-webkit-scrollbar {
    display: none;
}

#global {
    scrollbar-width: none;
}

body {
    overflow: scroll;
    box-sizing: border-box;
    color: #373737;
    background: #ffffff;
    font-size: 16px;
    font-family: $dev-serif-fonts;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    scrollbar-width: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 10px 0;
    font-weight: 700;
    color: #222222;
    font-family: $dev-serif-fonts;
    letter-spacing: -1px;
}

h1 {
    font-size: 36px;
    font-weight: 700;
}

h2 {
    padding-bottom: 10px;
    font-size: 32px;
    background: url("../images/bg_hr.png") repeat-x bottom;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 21px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

p {
    margin: 10px 0 15px 0;
}

a {
    text-decoration: none;
    color: #0f79d0;
    text-shadow: none;

    transition: color 0.5s ease;
    transition: text-shadow 0.5s ease;
    -webkit-transition: color 0.5s ease;
    -webkit-transition: text-shadow 0.5s ease;
    -moz-transition: color 0.5s ease;
    -moz-transition: text-shadow 0.5s ease;
    -o-transition: color 0.5s ease;
    -o-transition: text-shadow 0.5s ease;
    -ms-transition: color 0.5s ease;
    -ms-transition: text-shadow 0.5s ease;
}

a:hover,
a:focus {
    text-decoration: underline;
}

.active {
    text-decoration: underline;
    font-weight: bold;
}

em,
cite {
    font-style: italic;
}

strong {
    font-weight: bold;
}

img {
    position: relative;
    margin: 0 auto;
    max-width: 696px;
    padding: 5px;
    margin: 10px 0 10px 0;
    border: 1px solid #ebebeb;

    // box-shadow: 0 0 5px #ebebeb;
    // -webkit-box-shadow: 0 0 5px #ebebeb;
    // -moz-box-shadow: 0 0 5px #ebebeb;
    // -o-box-shadow: 0 0 5px #ebebeb;
    // -ms-box-shadow: 0 0 5px #ebebeb;
}

p img {
    display: inline;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    text-align: center;
    border: none;
}

pre,
code {
    font-family: $dev-editor-fonts;
    font-size: 0.875em;

    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
}

pre {
    padding: 5px;
    margin-top: 2%;
    //box-shadow: 0 0 10px rgba(0,0,0,.1);
    overflow: auto;
}

code {
    padding: 5px;
}

pre code {
    display: block;
    box-shadow: none;
}

blockquote {
    color: #666;
    margin-bottom: 20px;
    padding: 0 0 0 20px;
    border-left: 3px solid #bbb;
}

ul,
ol,
dl {
    margin-bottom: 15px;
}

ul {
    list-style-position: inside;
    list-style: square;
    padding-left: 20px;
}

ol {
    list-style-position: inside;
    list-style: decimal;
    padding-left: 20px;
}

dl dt {
    font-weight: bold;
}

dl dd {
    padding-left: 20px;
    font-style: italic;
}

dl p {
    padding-left: 20px;
    font-style: italic;
}

hr {
    height: 1px;
    margin-bottom: 5px;
    border: none;
    background: url("../images/bg_hr.png") repeat-x center;
}

table {
    border: 1px solid #373737;
    margin-bottom: 20px;
    text-align: left;
}

th {
    font-family: "Lucida Grande", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 10px;
    background: #373737;
    color: #fff;
}

td {
    padding: 10px;
    border: 1px solid #373737;
}

form {
    background: #f2f2f2;
    padding: 20px;
}

#logo {
    font-size: 9.9px;
    font-weight: 100;
    margin: 0;
    padding: 0;
    line-height: 1;
    text-align: center;

    max-width: 870px;
    max-height: 279px;
}

#blog_list {
    list-style: none;
    padding-left: 0px;
}

#blog_list h2 {
    font-size: 16px;
}

#blog_list .date {
    margin: 0;
}

#blog_list h6 {
    margin: 0;
}

#non-code {
    padding: 0px;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0);
}

#post-container {
    display: flex;
    justify-content: space-between;
    background: url("../images/bg_hr.png") repeat-x bottom;
}

#post-container #post-title {
    margin-bottom: 10px;
    padding-bottom: 0px; 
    background:rgba(0, 0, 0, 0);
}

#post-container #post-path {
    margin-bottom: 10px;
    padding-bottom: 0px;
    color: gray;
    background:rgba(0, 0, 0, 0);
    font-weight: normal;
    font-size: 14px;
}

p img {
    
    display: block;
    margin-left: auto;
    margin-right: auto;
}

img + em {
    display: block;
    font-size: 12px;
    padding-top: 2px;
    line-height: 1;
}

#filtering-container ul {
    display: inline-block;
    margin: 0;
    padding-left: 0;
    font-size: 12px;
}

#filtering-container h6 {
    font-weight: normal;
    font-size: 12px;
    display: inline-block;
    margin: 0;
}

#options-container {
    padding-top: 20px;
    display: block;
}

#search-container {
    display: inline-block;
}

#filtering-container {
    float: right;
    text-align: right;
    display: grid;
}

.video-container {
    height: 350px;
}

iframe {
    width: 100%;
    height: 100%;
}